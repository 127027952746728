import MaxWidth from 'components/functional/maxWidth'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import CustomCollapse from './creativeCustomCollapse'

const Creativetyles = styled.section`
  width: 100%;
  padding: 4rem 0;
  h2 {
    text-align: center;
  }
`
const Creative = ({ data }) => {
  const i = true
  return (
    <MaxWidth $transparent>
      <Creativetyles>
        <h2>Creative Team</h2>
        <CustomCollapse data={data} type="creative" />
      </Creativetyles>
    </MaxWidth>
  )
}

export default Creative
