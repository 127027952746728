import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const CastHeaderStyles = styled.div`
  width: 100%;
  padding: 3rem 0;
  h1 {
    text-align: center;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media ${media.xl} {
      margin-bottom: 3rem;
    }
  }
  p {
    text-align: center;
    margin: 2.5rem auto 0 auto;
    width: 90%;
  }
`
const CastHeader = () => {
  const i = true
  return (
    <CastHeaderStyles>
      <h1>Cast &amp; Creative</h1>
    </CastHeaderStyles>
  )
}

export default CastHeader
