import Cast from 'components/cast/cast'
import CastHeader from 'components/cast/castHeader'
import Creative from 'components/cast/creative'
import Layout from 'components/global/layout'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'
import React from 'react'

const CastAndCreative = ({ data }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  const cast = data.cast.nodes[0]
  const creative = data.creative.nodes
  const meta = {
    title: 'Cast & Creative | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} desktopHeader={desk} />
      <CastHeader />
      <Cast data={cast} />
      <Creative data={creative} />
    </Layout>
  )
}

export default CastAndCreative

export const CastCreativeQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "contact-us-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    cast: allCastJson {
      nodes {
        mainCast {
          name
          role
          image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  formats: [AUTO, WEBP, AVIF]
                )
              }
          }
          bio
        }
        understudy {
          name
          role
          image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  formats: [AUTO, WEBP, AVIF]
                )
              }
          }
          bio
        }
      }
    }
    creative: allCreativeJson {
      nodes {
        name
        role
        bio
      }
    }
  }
`
