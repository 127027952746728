import GetImage from 'components/functional/getImage'
import MaxWidth from 'components/functional/maxWidth'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import SingleCastMember from './singleCast'
import CustomCollapse from './castCustomCollapse'

const CastStyles = styled.section`
  width: 100%;
`
const Cast = ({ data }) => {
  const { mainCast, understudy } = data
  return (
    <MaxWidth $transparent $wooden>
      <CastStyles>
        <CustomCollapse data={mainCast} type="maincast" />
        <CustomCollapse data={understudy} type="maincast" />
      </CastStyles>
    </MaxWidth>
  )
}

export default Cast



