import GetImage from 'components/functional/getImage'
import React, { useState } from 'react'
import styled from 'styled-components'
import CrossIcon from 'svgs/close.svg'

const SingleCastStyles = styled.div`
  width: 100%;
  cursor: pointer;
  .image {
    width: 80%;
    margin: var(--auto);
    max-width: 270px;
  }

  > p {
    text-transform: uppercase;
    text-align: center;
  }
  .bio {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    flex-wrap: wrap;
    background: var(--main);
    border: solid var(--main) 5px;
    svg {
      fill: var(--white);
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
    }
    p {
      color: white;
    }
  }
  .bio-active {
    height: 10rem;
    visibility: visible;
    overflow: visible;
    position: absolute;
  }
`

const SingleCastMember = ({ data }) => {
  const [open, setOpen] = useState(false)

  return (
    <SingleCastStyles onClick={() => setOpen(!open)}>
      <div className="image">
        <GetImage data={data.image} alt={data.name} />
      </div>
      <p>{data.name}</p>
      <p className="red">{data.role}</p>
      <div className={open ? 'bio bio-active' : 'bio'}>
        <CrossIcon />
        <strong>{data.name}</strong>
        <p dangerouslySetInnerHTML={{ __html: data.bio }} />
      </div>
    </SingleCastStyles>
  )
}
export default SingleCastMember
